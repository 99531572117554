@tailwind base;
@tailwind components;
@tailwind utilities;
@import "index.css";

@layer base {
  :root, .light, .light-theme {
    --background: 240 10% 97%;              /* #f6f6f8 */
    --foreground: 262 14% 14%;              /* #211f27 */
  
    --muted: 255 15% 93%;                   /* #edecf1 */
    --muted-foreground: 255 7% 56%;         /* #8b8796 */
  
    --primary: 249 94% 43%;                 /* #3807d9 */
    --primary-foreground: 0 0% 100%;        /* #ffffff */
  
    --secondary: 225 100% 93%;              /* #dce4ff */
    --secondary-foreground: 262 14% 14%;    /* #211f27 */
  
    --accent: 229 100% 83%;                 /* #a5b5ff */
    --accent-foreground: 262 14% 14%;       /* #211f27 */
  
    --destructive: 1 77% 56%;               /* #e53935 */
    --destructive-foreground: 0 0% 100%;    /* #ffffff */
  
    --border: 255 10% 90%;                  /* #e5e3ea */
    --input: 255 15% 93%;                   /* #edecf1 */
  
    --card: 240 10% 98%;                    /* #f9f9fb */
    --card-foreground: 262 14% 14%;         /* #211f27 */
  
    --popover: 240 10% 98%;                 /* #f9f9fb */
    --popover-foreground: 262 14% 14%;      /* #211f27 */
  
    --ring: 229 100% 83%;                   /* #a5b5ff */
  
    --radius: 0.5rem;
  }
  
  
  .dark, .dark-theme {
    --background: 240 5% 7%;                /* #111113 */
    --foreground: 240 7% 94%;               /* #eeeef0 */
  
    --muted: 255 6% 15%;                    /* #232226 */
    --muted-foreground: 255 6% 45%;         /* #706c7b */
  
    --primary: 249 94% 43%;                 /* #3807d9 */
    --primary-foreground: 0 0% 100%;        /* #ffffff */
  
    --secondary: 240 56% 30%;               /* #222278 */
    --secondary-foreground: 240 7% 94%;     /* #eeeef0 */
  
    --accent: 236 44% 47%;                  /* #3d44b1 */
    --accent-foreground: 240 7% 94%;        /* #eeeef0 */
  
    --destructive: 0 68% 68%;               /* #e57373 */
    --destructive-foreground: 0 0% 100%;    /* #ffffff */
  
    --border: 255 5% 18%;                   /* #2a292f */
    --input: 255 6% 15%;                    /* #232226 */
  
    --card: 255 5% 11%;                     /* #1a191c */
    --card-foreground: 240 7% 94%;          /* #eeeef0 */
  
    --popover: 255 5% 11%;                  /* #1a191c */
    --popover-foreground: 240 7% 94%;       /* #eeeef0 */
  
    --ring: 236 44% 47%;                    /* #3d44b1 */
  
    --radius: 0.5rem;
  }
  
  
}

@layer base {
    * {
      @apply border-border;
    }
    body {
      @apply bg-background text-foreground;
      font-feature-settings: "rlig" 1, "calt" 1;
    }
}

.Toastify__close-button {
    @apply hidden;
}