.ToastViewport {
    --viewport-padding: 24px;
	position: fixed;
	bottom: 0;
    left: 50%;
    transform: translateX(-50%);
	display: flex;
	flex-direction: column;
	padding: 24px;
	z-index: 2147483647;
    align-items: center;
}

.ToastRoot {
	box-shadow:
        rgba(32, 0, 80, 0.06) 0px 12px 32px -16px,
		rgba(0, 0, 0, 0.05) 0px 8px 40px 0px;
	align-items: center;
}
.ToastRoot[data-state="open"] {
	animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.ToastRoot[data-state="closed"] {
	animation: hide 100ms ease-in;
}
.ToastRoot[data-swipe="move"] {
	transform: translateX(var(--radix-toast-swipe-move-x));
}
.ToastRoot[data-swipe="cancel"] {
	transform: translateX(0);
	transition: transform 200ms ease-out;
}
.ToastRoot[data-swipe="end"] {
	animation: swipeOut 100ms ease-out;
}

@keyframes hide {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes slideIn {
	from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes swipeOut {
	from {
		transform: translateX(var(--radix-toast-swipe-end-x));
	}
	to {
		transform: translateX(calc(100% + var(--viewport-padding)));
	}
}
