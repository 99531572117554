@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');


body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* To prevent horizontal scrolling */
  overflow-y: scroll;       /* Always makes the content scrollable but doesn't show the scrollbar by default */
  scrollbar-width: none;    /* For Firefox: Hide scrollbar */
  -webkit-font-smoothing: antialiased !important;
}

body::-webkit-scrollbar {
  display: none;            /* For Chrome, Safari, and Edge: Hide scrollbar */
}

:root {
  --toastify-toast-width: auto !important;
  --toastify-icon-color-info: white !important;
  --toastify-icon-color-success: white !important;
  --toastify-icon-color-warning: white !important;
  --toastify-icon-color-error: white !important;
}

.radix-themes {
  --default-font-family: 'Figtree', sans-serif !important; /* Your custom default font */ 
  --heading-font-family: 'Figtree', sans-serif !important; /* Your custom font for <Heading> components */ 
  --code-font-family: 'Figtree', sans-serif !important; /* Your custom font for <Code> components */ 
  --strong-font-family: 'Figtree', sans-serif !important; /* Your custom font for <Strong> components */ 
  --em-font-family: 'Figtree', sans-serif !important; /* Your custom font for <Em> components */ 
  --quote-font-family: 'Figtree', sans-serif !important; /* Your custom font for <Quote> components */
}

/* B-Blue Light Theme Colour Palatte */
:root, .light, .light-theme {
  --blue-1: #f9fafc;
  --blue-2: #f3f5fe;
  --blue-3: #eaeeff;
  --blue-4: #dce4ff;
  --blue-5: #cdd9ff;
  --blue-6: #bbcaff;
  --blue-7: #a5b5ff;
  --blue-8: #8798ff;
  --blue-9: #3807d9;
  --blue-10: #2f00c0;
  --blue-11: #4540e7;
  --blue-12: #222471;

  --blue-a1: #3d7d7f04;
  --blue-a2: #174afe0a;
  --blue-a3: #eaeeff;
  --blue-a4: #dce4ff;
  --blue-a5: #cdd9ff;
  --blue-a6: #bbcaff;
  --blue-a7: #a5b5ff;
  --blue-a8: #8798ff;
  --blue-a9: #3200d8f8;
  --blue-a10: #2f00c0;
  --blue-a11: #0802dfbf;
  --blue-a12: #00025bdd;

  --blue-contrast: #fff;
  --blue-surface: #f1f4fecc;
  --blue-indicator: #3807d9;
  --blue-track: #3807d9;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --blue-1: oklch(98.5% 0.003 274.7);
      --blue-2: oklch(97.2% 0.0122 274.7);
      --blue-3: oklch(95.1% 0.0241 274.7);
      --blue-4: oklch(92.4% 0.0481 274.7);
      --blue-5: oklch(89.2% 0.0722 274.7);
      --blue-6: oklch(85.2% 0.0977 274.7);
      --blue-7: oklch(79.4% 0.1221 274.7);
      --blue-8: oklch(71.6% 0.1617 274.7);
      --blue-9: oklch(42.4% 0.2635 274.7);
      --blue-10: oklch(36.7% 0.2635 274.7);
      --blue-11: oklch(49.7% 0.2404 274.7);
      --blue-12: oklch(31.4% 0.1304 274.7);

      --blue-a1: color(display-p3 0.0118 0.3373 0.3451 / 0.012);
      --blue-a2: color(display-p3 0.0118 0.2275 0.8902 / 0.036);
      --blue-a3: color(display-p3 0.0118 0.1843 0.9412 / 0.068);
      --blue-a4: color(display-p3 0.0078 0.2314 0.9608 / 0.124);
      --blue-a5: color(display-p3 0.0039 0.2235 0.9529 / 0.179);
      --blue-a6: color(display-p3 0.0039 0.1961 0.949 / 0.247);
      --blue-a7: color(display-p3 0.0039 0.1451 0.9412 / 0.334);
      --blue-a8: color(display-p3 0.0039 0.1137 0.9451 / 0.453);
      --blue-a9: color(display-p3 0.1647 0 0.8078 / 0.957);
      --blue-a10: color(display-p3 0.1529 0 0.7176 / 0.989);
      --blue-a11: color(display-p3 0.0235 0 0.8314 / 0.747);
      --blue-a12: color(display-p3 0 0.0078 0.3412 / 0.866);

      --blue-contrast: #fff;
      --blue-surface: color(display-p3 0.9451 0.9569 0.9922 / 0.8);
      --blue-indicator: oklch(42.4% 0.2635 274.7);
      --blue-track: oklch(42.4% 0.2635 274.7);
    }
  }
}

/* B-Gray Light Theme Colour Palatte */
:root, .light, .light-theme {
  --gray-1: #f9f9fb;
  --gray-2: #f6f6f8;
  --gray-3: #edecf1;
  --gray-4: #e5e3ea;
  --gray-5: #dedce4;
  --gray-6: #d6d4dd;
  --gray-7: #ccc9d4;
  --gray-8: #b9b5c4;
  --gray-9: #8b8796;
  --gray-10: #807d8b;
  --gray-11: #625f6a;
  --gray-12: #211f27;

  --gray-a1: #3d3d3f04;
  --gray-a2: #22222407;
  --gray-a3: #1b0c3c11;
  --gray-a4: #1b073a1a;
  --gray-a5: #15053621;
  --gray-a6: #10043129;
  --gray-a7: #11023134;
  --gray-a8: #0f013148;
  --gray-a9: #0a022077;
  --gray-a10: #06001a81;
  --gray-a11: #0500109f;
  --gray-a12: #020009e0;

  --gray-contrast: #FFFFFF;
  --gray-surface: #ffffffcc;
  --gray-indicator: #8b8796;
  --gray-track: #8b8796;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --gray-1: oklch(98.3% 0.0021 297.9);
      --gray-2: oklch(97.3% 0.0038 297.9);
      --gray-3: oklch(94.5% 0.0069 297.9);
      --gray-4: oklch(92% 0.0091 297.9);
      --gray-5: oklch(89.7% 0.0117 297.9);
      --gray-6: oklch(87.4% 0.0135 297.9);
      --gray-7: oklch(84.1% 0.0166 297.9);
      --gray-8: oklch(78% 0.0225 297.9);
      --gray-9: oklch(63.2% 0.0226 297.9);
      --gray-10: oklch(59.7% 0.0215 297.9);
      --gray-11: oklch(49.2% 0.0186 297.9);
      --gray-12: oklch(24.5% 0.0156 297.9);

      --gray-a1: color(display-p3 0.0118 0.0118 0.0196 / 0.012);
      --gray-a2: color(display-p3 0.0118 0.0118 0.0196 / 0.024);
      --gray-a3: color(display-p3 0.0706 0.0118 0.2 / 0.064);
      --gray-a4: color(display-p3 0.0863 0.0078 0.1725 / 0.1);
      --gray-a5: color(display-p3 0.0627 0.0039 0.1647 / 0.127);
      --gray-a6: color(display-p3 0.0549 0.0039 0.1569 / 0.159);
      --gray-a7: color(display-p3 0.0431 0.0039 0.1686 / 0.203);
      --gray-a8: color(display-p3 0.0431 0.0039 0.1765 / 0.282);
      --gray-a9: color(display-p3 0.0275 0.0039 0.1137 / 0.465);
      --gray-a10: color(display-p3 0.0157 0 0.0941 / 0.504);
      --gray-a11: color(display-p3 0.0157 0 0.0588 / 0.624);
      --gray-a12: color(display-p3 0.0078 0 0.0314 / 0.877);

      --gray-contrast: #FFFFFF;
      --gray-surface: color(display-p3 1 1 1 / 80%);
      --gray-indicator: oklch(63.2% 0.0226 297.9);
      --gray-track: oklch(63.2% 0.0226 297.9);
    }
  }
}

/* B-Blue Dark Theme Colour Palatte */
.dark, .dark-theme {
  --blue-1: #0d101f;
  --blue-2: #12152a;
  --blue-3: #1b1f55;
  --blue-4: #222278;
  --blue-5: #2a2c8b;
  --blue-6: #33379a;
  --blue-7: #3d44b1;
  --blue-8: #4a50d1;
  --blue-9: #3807d9;
  --blue-10: #2f00c0;
  --blue-11: #9daeff;
  --blue-12: #d6e0ff;

  --blue-a1: #0000ff0f;
  --blue-a2: #1b37fe1b;
  --blue-a3: #3442ff49;
  --blue-a4: #3838fd6f;
  --blue-a5: #4246ff83;
  --blue-a6: #4c53ff93;
  --blue-a7: #525cfeac;
  --blue-a8: #585fffce;
  --blue-a9: #3f06fed7;
  --blue-a10: #3a00ffbc;
  --blue-a11: #9daeff;
  --blue-a12: #d6e0ff;

  --blue-contrast: #fff;
  --blue-surface: #13194380;
  --blue-indicator: #3807d9;
  --blue-track: #3807d9;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    .dark, .dark-theme {
      --blue-1: oklch(17.8% 0.032 274.7);
      --blue-2: oklch(20.6% 0.0418 274.7);
      --blue-3: oklch(27.1% 0.0975 274.7);
      --blue-4: oklch(31.8% 0.1411 274.7);
      --blue-5: oklch(35.9% 0.153 274.7);
      --blue-6: oklch(40% 0.1578 274.7);
      --blue-7: oklch(44.9% 0.1701 274.7);
      --blue-8: oklch(50.6% 0.1951 274.7);
      --blue-9: oklch(42.4% 0.2635 274.7);
      --blue-10: oklch(36.7% 0.2635 274.7);
      --blue-11: oklch(77.6% 0.1823 274.7);
      --blue-12: oklch(91.3% 0.0594 274.7);

      --blue-a1: color(display-p3 0 0 0.9961 / 0.055);
      --blue-a2: color(display-p3 0.1451 0.2235 1 / 0.101);
      --blue-a3: color(display-p3 0.2275 0.2706 1 / 0.274);
      --blue-a4: color(display-p3 0.2314 0.2314 1 / 0.412);
      --blue-a5: color(display-p3 0.2667 0.2824 1 / 0.492);
      --blue-a6: color(display-p3 0.3137 0.3373 0.9961 / 0.551);
      --blue-a7: color(display-p3 0.3412 0.3765 1 / 0.648);
      --blue-a8: color(display-p3 0.3569 0.3843 1 / 0.778);
      --blue-a9: color(display-p3 0.2353 0.0392 1 / 0.803);
      --blue-a10: color(display-p3 0.2078 0 1 / 0.702);
      --blue-a11: color(display-p3 0.6431 0.6941 1 / 0.975);
      --blue-a12: color(display-p3 0.8588 0.8902 1 / 0.988);

      --blue-contrast: #fff;
      --blue-surface: color(display-p3 0.0706 0.0941 0.251 / 0.5);
      --blue-indicator: oklch(42.4% 0.2635 274.7);
      --blue-track: oklch(42.4% 0.2635 274.7);
    }
  }
}

/* B-Gray Dark Theme Color Palatte */
.dark, .dark-theme {
  --gray-1: #111113;
  --gray-2: #1a191c;
  --gray-3: #232226;
  --gray-4: #2a292f;
  --gray-5: #323037;
  --gray-6: #3b3941;
  --gray-7: #494650;
  --gray-8: #625f6c;
  --gray-9: #706c7b;
  --gray-10: #7d7988;
  --gray-11: #b5b2bf;
  --gray-12: #eeeef0;

  --gray-a1: #1111bb03;
  --gray-a2: #d1bcfb0c;
  --gray-a3: #d9cefa17;
  --gray-a4: #d3cbf921;
  --gray-a5: #dfd2fe29;
  --gray-a6: #dfd5fc34;
  --gray-a7: #e3d8fe44;
  --gray-a8: #e4dcfe62;
  --gray-a9: #e6ddff72;
  --gray-a10: #e9e1ff80;
  --gray-a11: #f0ecfebb;
  --gray-a12: #fdfdffef;

  --gray-contrast: #FFFFFF;
  --gray-surface: rgba(0, 0, 0, 0.05);
  --gray-indicator: #706c7b;
  --gray-track: #706c7b;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    .dark, .dark-theme {
      --gray-1: oklch(17.8% 0.0058 297.9);
      --gray-2: oklch(21.5% 0.0055 297.9);
      --gray-3: oklch(25.5% 0.0075 297.9);
      --gray-4: oklch(28.4% 0.0103 297.9);
      --gray-5: oklch(31.4% 0.0122 297.9);
      --gray-6: oklch(35% 0.0137 297.9);
      --gray-7: oklch(40.2% 0.0166 297.9);
      --gray-8: oklch(49.2% 0.0217 297.9);
      --gray-9: oklch(54% 0.023 297.9);
      --gray-10: oklch(58.6% 0.0226 297.9);
      --gray-11: oklch(77% 0.0191 297.9);
      --gray-12: oklch(94.9% 0.0036 297.9);

      --gray-a1: color(display-p3 0.0667 0.0667 0.9412 / 0.009);
      --gray-a2: color(display-p3 0.8196 0.7373 0.9882 / 0.047);
      --gray-a3: color(display-p3 0.8627 0.8196 0.9922 / 0.089);
      --gray-a4: color(display-p3 0.8392 0.8078 0.9961 / 0.127);
      --gray-a5: color(display-p3 0.898 0.8471 1 / 0.156);
      --gray-a6: color(display-p3 0.898 0.8588 1 / 0.198);
      --gray-a7: color(display-p3 0.8902 0.8627 0.9961 / 0.261);
      --gray-a8: color(display-p3 0.8941 0.8706 0.9961 / 0.379);
      --gray-a9: color(display-p3 0.902 0.8745 1 / 0.442);
      --gray-a10: color(display-p3 0.9098 0.8863 1 / 0.496);
      --gray-a11: color(display-p3 0.9451 0.9333 1 / 0.727);
      --gray-a12: color(display-p3 0.9922 0.9922 1 / 0.937);

      --gray-contrast: #FFFFFF;
      --gray-surface: color(display-p3 0 0 0 / 5%);
      --gray-indicator: oklch(54% 0.023 297.9);
      --gray-track: oklch(54% 0.023 297.9);
    }
  }
}

/* B-Background Light Theme */
:root, .light, .light-theme, .radix-themes {
  --color-background: var(--gray-2);
}

/* B-Background Dark Theme */
.dark, .dark-theme, :is(.dark, .dark-theme) :where(.radix-themes:not(.light, .light-theme)) {
  --color-background: var(--gray-1);
}
