:root {
  --chart-font-colour-light: #625f6a;
  --chart-font-colour-dark: #b5b2bf;
  --chart-neutral-colour: #bbcaff;
  --chart-neutral-border-colour: #4540e7;
  --chart-neutral-colour-dark: #33379a;
  --chart-neutral-border-colour-dark: #9daeff;
  --chart-positive-colour: #4a50d1;
  --chart-grid-colour-dark: #3b3941;
  --chart-grid-colour-light: #d6d4dd;
  --chart-points-bg-colour-dark: #111113;
  --chart-points-bg-colour-light: #ffffffe5;
  --chart-bar-colour: #bbcaff;
  --chart-bar-border-colour: #4540e7;
  --chart-bar-colour-dark: #33379a;
  --chart-bar-border-colour-dark: #9daeff;
  --chart-new-connection-colour: #b2ddb5;
  --chart-new-connection-border-colour: #2a7e3b;
  --chart-new-connection-colour-dark: #2d5736;
  --chart-new-connection-border-colour-dark: #71d083;
  --chart-returning-connection-colour-dark: #5c3d05;
  --chart-returning-connection-border-colour-dark: #ffca16;
  --chart-returning-connection-colour: #f3d673;
  --chart-returning-connection-border-colour: #ab6400;
  --chart-age-over-65-colour: #e9c2ec;
  --chart-age-55-64-colour: #fdbdaf;
  --chart-age-45-54-colour: #c1d0ff;
  --chart-age-35-44-colour: #9ddde7;
  --chart-age-25-34-colour: #b2ddb5;
  --chart-age-18-24-colour: #c2da91;
  --chart-age-below-18-colour: #d4cafe;
  --chart-age-over-65-colour-dark: #5e3061;
  --chart-age-55-64-colour-dark: #6e2920;
  --chart-age-45-54-colour-dark: #304384;
  --chart-age-35-44-colour-dark: #045468;
  --chart-age-25-34-colour-dark: #2d5736;
  --chart-age-18-24-colour-dark: #3d522a;
  --chart-age-below-18-colour-dark: #473876;
  --chart-age-over-65-border-colour: #953ea3;
  --chart-age-55-64-border-colour: #d13415;
  --chart-age-45-54-border-colour: #3a5bc7;
  --chart-age-35-44-border-colour: #107d98;
  --chart-age-25-34-border-colour: #2a7e3b;
  --chart-age-18-24-border-colour: #5c7c2f;
  --chart-age-below-18-border-colour: #6550b9;
  --chart-age-over-65-border-colour-dark: #e796f3;
  --chart-age-55-64-border-colour-dark: #ff977d;
  --chart-age-45-54-border-colour-dark: #9eb1ff;
  --chart-age-35-44-border-colour-dark: #4ccce6;
  --chart-age-25-34-border-colour-dark: #71d083;
  --chart-age-18-24-border-colour-dark: #bde56c;
  --chart-age-below-18-border-colour-dark: #baa7ff;
  --chart-gender-no-response-colour: #9ddde7;
  --chart-gender-other-colour: #b2ddb5;
  --chart-gender-female-colour: #c2da91;
  --chart-gender-male-colour: #d4cafe;
  --chart-gender-no-response-colour-dark: #045468;
  --chart-gender-other-colour-dark: #2d5736;
  --chart-gender-female-colour-dark: #3d522a;
  --chart-gender-male-colour-dark: #473876;
  --chart-gender-no-response-border-colour: #107d98;
  --chart-gender-other-border-colour: #2a7e3b;
  --chart-gender-female-border-colour: #5c7c2f;
  --chart-gender-male-border-colour: #6550b9;
  --chart-gender-no-response-border-colour-dark: #4ccce6;
  --chart-gender-other-border-colour-dark: #71d083;
  --chart-gender-female-border-colour-dark: #bde56c;
  --chart-gender-male-border-colour-dark: #baa7ff;

  /* New presence chart colors */
  --chart-guest-border-colour: #baa7ff;
  --chart-guest-fill-colour: #473876;
  --chart-passerby-border-colour: #b5b2bf;
  --chart-passerby-fill-colour: #3b3941;
  --chart-passerby-fill-colour-light: #d6d4dd;
  --chart-guest-fill-colour-light: #d4cafe;
  --chart-guest-border-colour-light: #6550b9;
  --chart-passerby-border-colour-light: #625f6a;
}
